.login-container {
    display: flex;
    height: 100vh;
    width: 100%;
}

.form-section {
    flex: 1;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}

.form-wrapper {
    width: 60%;
    margin: 0 auto;
}

.submit-button-wrapper {
    width: 60%;
    margin: 0 auto;
}

.heading {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
}

.sub-heading {
    margin-top: 5px;
    font-size: 18px;
    text-align: center;
}

.form {
    width: 70%;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.input-half {
    width: 48%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.input-full {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.checkbox {
    margin-right: 10px;
}


.checkbox-label {
    font-size: 14px;
}

.submit-button {
    background-color: #6a0dad;
    color: #fff;
    padding: 15px;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    width: 100%;
    margin-top: 5px;
}

.password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input-wrapper input {
    width: 100%;
    padding-right: 40px;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
    margin-bottom: 10px;
}

.password-toggle-icon:hover {
    opacity: 1;
}

.or-section {
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}

.social-buttons-wrapper {
    width: 60%;
    margin: 0 auto;
}

.social-buttons {
    display: flex;
    flex-direction: column;
}

.social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    width: 100%;
}

.google-button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.google-icon {
    color: #DB4437;
}

.account-footer {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
}

.image-section-cover {
    flex: 1;
    background: url(../../Assests/SignIn.png) no-repeat center center;
    background-size: cover;
}

.forgot-password-wrapper {
    text-align: center;
    margin-top: 10px;
}

.forgot-password-link {
    color: #6a0dad;
    text-decoration: none;
    font-size: 14px;
}

.forgot-password-link:hover {
    text-decoration: underline;
}

.forgot-password-link:focus {
    outline: none;
    text-decoration: underline;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
}

.validation-error {
    color: red;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

.message {
    color: red;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}


.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .forgot-password-wrapper {
        margin-top: 15px;
    }

    .forgot-password-link {
        font-size: 16px;
    }
}


@media (max-width: 768px) {
    .form-section {
        padding: 20px;
    }

    .heading {
        font-size: 24px;
    }

    .sub-heading {
        font-size: 16px;
    }

    .input-half {
        width: 100%;
        margin-bottom: 10px;
    }

    .or-section {
        font-size: 12px;
    }

    .submit-button {
        padding: 12px;
    }

    .social-button {
        padding: 8px;
    }

    .account-footer {
        font-size: 12px;
    }

    .image-section-cover {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .form-section {
        padding: 30px;
    }

    .heading {
        font-size: 28px;
    }

    .sub-heading {
        font-size: 18px;
    }

    .input-half {
        width: 48%;
    }

    .or-section {
        font-size: 14px;
    }

    .submit-button {
        padding: 14px;
    }

    .social-button {
        padding: 10px;
    }

    .account-footer {
        font-size: 14px;
    }
}